import { render, staticRenderFns } from "./wsTipTap.vue?vue&type=template&id=6959ccba&"
import script from "./wsTipTap.vue?vue&type=script&lang=js&"
export * from "./wsTipTap.vue?vue&type=script&lang=js&"
import style0 from "./wsTipTap.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
